import { useQuery } from '@tanstack/react-query'
import { aOrganization, useAtomValue } from '~/atoms'
import { type Company, type ModelFind } from '~/models'
import { type ApiResponse } from '../utils'

type FetchCompaniesResponse = ApiResponse & {
  companies: Company[]
}

async function fetchCompanies(
  find: ModelFind
): Promise<FetchCompaniesResponse> {
  const response = await fetch('/api/companies', {
    body: JSON.stringify(find),
    method: 'POST',
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading companies', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useAdminCompaniesQuery(
  key: string[],
  find?: {},
  options: { enabled?: boolean; refetchOnWindowFocus?: boolean } = {
    enabled: true,
    refetchOnWindowFocus: false,
  }
) {
  const organization = useAtomValue(aOrganization)
  const companiesQuery = useQuery({
    queryKey: key,
    queryFn: () =>
      fetchCompanies({ ...find, 'organization._id': organization!._id }),
    // keepPreviousData: true,
    refetchOnWindowFocus: options.refetchOnWindowFocus,
    enabled: options.enabled,
  })
  return {
    isLoading:
      companiesQuery.isLoading ||
      companiesQuery.isFetching ||
      companiesQuery.isRefetching ||
      companiesQuery.isPending,
    isError: companiesQuery.isError,
    companies: companiesQuery.data?.companies || [],
  }
}
